<template>
  <v-container fluid>
    <form
      action
      data-vv-scope="user-form"
      @submit.prevent="validateForm('user-form')"
    >
      <!-- <v-tabs v-model="tab">
        <v-tab href="#roles">{{ $tc("role", 2) }}</v-tab>
        <v-tab href="#changePassword">{{
          $t("change", { name: $t("password") })
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item value="roles">
          <users class="my-5" />
        </v-tab-item>
        <v-tab-item value="changePassword" id="changePassword">-->
      <base-material-alert v-if="userProcessed" color="success" dark>{{
        $t(text_processed)
      }}</base-material-alert>
      <v-row v-if="user">
        <v-col cols="12" md="4" class="py-0">
          <label v-text="$t('actualpassword')"></label>
          <v-text-field
            outlined
            dense
            v-model="user.password"
            data-vv-validate-on="blur"
            v-validate="'required|min:2'"
            :append-icon="show.password ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="show.password = !show.password"
            :type="show.password ? 'text' : 'password'"
            :error-messages="errors.collect('user-form.password')"
            color="primary"
            v-bind:class="{ 'error--text': error_current }"
            hide-details
            data-vv-name="password"
            @change="error_current = false"
          />
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <label v-text="$t('new_password')"></label>
          <v-text-field
            outlined
            dense
            v-model="user.newPassword"
            data-vv-validate-on="blur"
            v-validate="'required|min:2|is_not:' + user.password"
            :append-icon="show.newPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @click:append="show.newPassword = !show.newPassword"
            :type="show.newPassword ? 'text' : 'password'"
            :error-messages="errors.collect('user-form.newPassword')"
            color="primary"
            hide-details
            data-vv-name="newPassword"
        /></v-col>
        <v-col cols="12" md="4" class="py-0">
          <label v-text="$t('new_password2')"></label>
          <v-text-field
            outlined
            dense
            v-model="user.newPassword2"
            data-vv-validate-on="blur"
            v-validate="
              'required|min:2|is:' +
              user.newPassword +
              '|is_not:' +
              user.password
            "
            :append-icon="show.newPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
            :append-outer-icon="
              user.newPassword2
                ? user.newPassword2 == user.newPassword &&
                  user.newPassword !== user.password
                  ? 'mdi-check'
                  : 'mdi-close'
                : ''
            "
            hide-details
            @click:append="show.newPassword2 = !show.newPassword2"
            :type="show.newPassword2 ? 'text' : 'password'"
            :error-messages="errors.collect('user-form.newPassword2')"
            color="primary"
            data-vv-name="newPassword2"
        /></v-col>
        <v-row class="pa-3 pb-0">
          <v-col cols="2">
            <v-btn
              type="submit"
              style="height: 30px !important; width: 100%; color: #363533; width: 170px !important"
              elevation="0"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon
              >{{ $t("save", { name: $t("") }) }}</v-btn
            ></v-col
          >
        </v-row>
      </v-row>
      <!--  </v-tab-item>
        <v-tab-item value="roles"> </v-tab-item>
      </v-tabs-items>-->
    </form>
  </v-container>
</template>

<script>
export default {
  name: "PasswordChange",
  mounted() {
    this.tab = this.$route.params.type ? this.$route.params.type : "profile";
  },
  components: {
    users: () => import("@/views/users/UserList"),
  },
  data() {
    return {
      dialog: false,
      userProcessed: false,
      user: {
        password: null,
        newPassword: null,
        newPassword2: null,
      },
      show: {
        password: false,
        newPassword: false,
        newPassword2: false,
      },
      error_current: false,
      tab: null,
    };
  },
  methods: {
    async validateForm(scope) {
      //console.log("validando");
      this.errors.clear();
      this.error_current = false;

      this.$validator.validateAll(scope).then((result) => {
        //console.log("validated");
        //console.log(result);
        if (result) {
          this.$confirm(this.$t("are_you_change_password"), "", "").then(() => {
            //https://sweetalert2.github.io/#configuration
            this.userSaved = false;
            this.validationErrors = [];
            this.$http({
              url: "users/auth",
              body: {
                password: this.user.password,
                newPassword: this.user.newPassword,
                newPassword2: this.user.newPassword2,
              },
              method: "post",
              responseType: "json",
              before: function (request) {
                this.$store.commit(
                  "app/SET_PROCESSING",
                  { show: true, message: null },
                  { root: true }
                );
              },
            })
              .then((response) => {
                this.$alert(this.$t("password_update_success"), "", "");
                this.user = {
                  password: null,
                  newPassword: null,
                  newPassword2: null,
                };
                this.show = {
                  password: false,
                  newPassword: false,
                  newPassword2: false,
                };
              })
              .catch((errorResponse) => {
                //this.generateValidationErrors(errorResponse);
                this.$alert(this.$t("password_update_error"), "", "");
                this.error_current = true;
              })
              .finally(() => {
                this.$store.commit(
                  "app/SET_PROCESSING",
                  { show: false, message: null },
                  { root: true }
                );
                this.errors.clear();
              });
          });
        }
      });
    },
  },
};
</script>

<style lang="sass">
#changePassword
  label
    display: block
    height: 25px
</style>